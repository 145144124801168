import React from 'react'
import {Link} from 'gatsby';
import Layout from 'layouts';
import {Job} from 'components/site';
import {Row, Col} from 'react-bootstrap';

export default (props) => {
  var jobs = props.pageContext.jobs;
  const groupedJobs = jobs.reduce((acc, job) => {
    const companyName = job["Company Name"][0];
    (acc[companyName] = acc[companyName] || []).push(job);
    return acc;
  }, {});
    return (
      <Layout {...props} >
      <Row noGutters style={{padding: '30px 0px 45px 0px', backgroundColor: '#f7f8f8'}}>
        <Col md={{span: 10, offset: 1}} style={{padding: 0}}>
        {Object.entries(groupedJobs).map((group) => <JobGroup name={group[0]} jobs={group[1]} />)}
        </Col>
      </Row>
      </Layout>
    )
}

const JobGroup = (props) => (
  <div>
    <div className="progression-row pro-news-press" style={{paddingTop: 0}}>
        <div className="width-container-pro" style={{padding: '0 20px'}}>
            <h3 style={{marginBottom: '10px', fontSize: '26px'}}>{props.name}</h3>
        </div>
    </div>
    <div className="pro-news-press-grid" style={{marginBottom: 0, paddingBottom: '30px'}}>
      <div className="width-container-pro row">
          {props.jobs && props.jobs.map((job, i) => (
            <Job {...job} />
          ))}
      </div>
    </div>
  </div>
)